import React from "react";
const variantClasses = {
  h1: "tw-font-normal lg:tw-text-[52px] xl:tw-text-[65px] tw-text-[73.56px] 2xl:tw-text-[73px] 3xl:tw-text-[88px]",
  h2: "tw-font-normal lg:tw-text-[52px] xl:tw-text-[65px] tw-text-[73.45px] 2xl:tw-text-[73px] 3xl:tw-text-[88px]",
  h3: "tw-font-normal lg:tw-text-[47px] xl:tw-text-[59px] tw-text-[66.41px] 2xl:tw-text-[66px] 3xl:tw-text-[79px]",
  h4: "tw-font-normal lg:tw-text-[27px] xl:tw-text-[34px] tw-text-[39.09px] 2xl:tw-text-[39px] 3xl:tw-text-[46px]",
  h5: "tw-font-normal lg:tw-text-[23px] xl:tw-text-[29px] tw-text-[33.12px] 2xl:tw-text-[33px] 3xl:tw-text-[39px]",
  h6: "tw-font-bold lg:tw-text-[17px] xl:tw-text-[21px] tw-text-[24.03px] 2xl:tw-text-[24px] 3xl:tw-text-[28px]",
  body1: "tw-font-bold lg:tw-text-[17px] xl:tw-text-[21px] tw-text-[24px] 3xl:tw-text-[28px]",
  body2:
    "tw-font-normal lg:tw-text-[15px] xl:tw-text-[19px] tw-text-[22.36px] 2xl:tw-text-[22px] 3xl:tw-text-[26px]",
  body3:
    "tw-font-normal lg:tw-text-[15px] xl:tw-text-[19px] tw-text-[21.6px] 2xl:tw-text-[21px] 3xl:tw-text-[25px]",
  body4:
    "tw-font-normal lg:tw-text-[15px] xl:tw-text-[18px] tw-text-[21.11px] 2xl:tw-text-[21px] 3xl:tw-text-[25px]",
  body5:
    "tw-font-normal lg:tw-text-[14px] xl:tw-text-[18px] tw-text-[20.4px] 2xl:tw-text-[20px] 3xl:tw-text-[24px]",
  body6:
    "tw-font-normal lg:tw-text-[14px] xl:tw-text-[18px] tw-text-[20.32px] 2xl:tw-text-[20px] 3xl:tw-text-[24px]",
  body7:
    "tw-font-normal lg:tw-text-[14px] xl:tw-text-[17px] tw-text-[20px] 3xl:tw-text-[24px]",
  body8:
    "tw-font-normal lg:tw-text-[14px] xl:tw-text-[17px] tw-text-[19.99px] 2xl:tw-text-[20px] 3xl:tw-text-[24px]",
  body9:
    "tw-font-normal lg:tw-text-[14px] xl:tw-text-[17px] tw-text-[19.97px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body10:
    "tw-font-normal lg:tw-text-[14px] xl:tw-text-[17px] tw-text-[19.96px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body11:
    "tw-font-normal lg:tw-text-[13px] xl:tw-text-[17px] tw-text-[19.55px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body12:
    "tw-font-normal lg:tw-text-[13px] xl:tw-text-[17px] tw-text-[19.24px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body13:
    "lg:tw-text-[13px] xl:tw-text-[17px] tw-text-[19.23px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body14:
    "tw-font-normal lg:tw-text-[13px] xl:tw-text-[17px] tw-text-[19.21px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body15:
    "lg:tw-text-[13px] xl:tw-text-[17px] tw-text-[19.2px] 2xl:tw-text-[19px] 3xl:tw-text-[23px]",
  body16:
    "tw-font-normal lg:tw-text-[13px] xl:tw-text-[16px] tw-text-[18.63px] 2xl:tw-text-[18px] 3xl:tw-text-[22px]",
  body17:
    "tw-font-normal lg:tw-text-[13px] xl:tw-text-[16px] tw-text-[18.33px] 2xl:tw-text-[18px] 3xl:tw-text-[22px]",
  body18:
    "tw-font-normal lg:tw-text-[12px] xl:tw-text-[16px] tw-text-[18.03px] 2xl:tw-text-[18px] 3xl:tw-text-[21px]",
  body19:
    "tw-font-normal lg:tw-text-[12px] xl:tw-text-[16px] tw-text-[18.01px] 2xl:tw-text-[18px] 3xl:tw-text-[21px]",
  body20:
    "tw-font-normal lg:tw-text-[12px] xl:tw-text-[16px] tw-text-[18px] 3xl:tw-text-[21px]",
  body21:
    "tw-font-light lg:tw-text-[12px] xl:tw-text-[15px] tw-text-[17.36px] 2xl:tw-text-[17px] 3xl:tw-text-[20px]",
  body22:
    "tw-font-normal lg:tw-text-[11px] xl:tw-text-[14px] tw-text-[16.6px] 2xl:tw-text-[16px] 3xl:tw-text-[19px]",
  body23:
    "tw-font-normal lg:tw-text-[11px] xl:tw-text-[14px] tw-text-[16.56px] 2xl:tw-text-[16px] 3xl:tw-text-[19px]",
  body24:
    "tw-font-normal lg:tw-text-[11px] xl:tw-text-[13px] tw-text-[15.52px] 2xl:tw-text-[15px] 3xl:tw-text-[18px]",
  body25:
    "tw-font-medium xl:tw-text-[11px] tw-text-[12.91px] 2xl:tw-text-[12px] 3xl:tw-text-[15px] lg:tw-text-[9px]",
  body26:
    "tw-font-normal xl:tw-text-[11px] tw-text-[12.42px] 2xl:tw-text-[12px] 3xl:tw-text-[14px] lg:tw-text-[8px]",
  body27:
    "tw-font-normal xl:tw-text-[10px] tw-text-[11.38px] 2xl:tw-text-[11px] 3xl:tw-text-[13px] lg:tw-text-[8px]",
};
const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
