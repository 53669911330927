import { Grid } from "components";
import { Layout } from "components/Layout";
import React from "react";
import { Row } from "reactstrap";
import "../components/Feature-Section/features.css";
import FeatureCard from "../components/Header/FeatureCard";
import { FeatureData } from "./FeatureData";

const Features = () => {
  return (
    <Layout>
      <section className="tw-w-full">
      <span className="tw-prose lg:tw-prose-xl">
        <h2 className="tw-mb-5 lg:tw-mb-12 tw-text-2xl tw-text-center">Features of Brainpiece</h2>
        </span>
        <Grid className="tw-max-w-[962.67px] tw-mx-auto tw-w-full tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-6 lg:tw-gap-12">
          {FeatureData?.map((item, index) => (
            <Row className="tw-w-full" key={index}>
              <FeatureCard feature={item} />
            </Row>
          ))}
        </Grid>
      </section>
    </Layout>
  );
};

export default Features;
