import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder6: "tw-rounded-radius621",
  RoundedBorder19: "tw-rounded-radius195",
  RoundedBorder14: "tw-rounded-radius1488",
};
const variants = {
  FillDeeppurpleA200: "tw-bg-deep_purple_A200 tw-text-white_A700",
  FillDeeppurpleA100: "tw-bg-deep_purple_A100 tw-text-white_A700",
};
const sizes = {
  sm: "tw-p-[10px] 3xl:tw-p-[12px] lg:tw-p-[7px] xl:tw-p-[8px]",
  md: "tw-p-[13px] xl:tw-p-[15px] 2xl:tw-p-[18px]",
  lg: "tw-p-[16px] xl:tw-p-[20px] 2xl:tw-p-[23px] 3xl:tw-p-[27px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} common-button `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder6",
    "RoundedBorder19",
    "RoundedBorder14",
  ]),
  variant: PropTypes.oneOf(["FillDeeppurpleA200", "FillDeeppurpleA100"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};
Button.defaultProps = {
  className: "",
  shape: "",
  variant: "FillDeeppurpleA200",
  size: "sm",
};

export { Button };
