import React from "react";
import "./newsletter.css";
import { Container, Row, Col } from "reactstrap";

const Newsletter = () => {
  return (
    <section>
      <Container className="newsletter purple-bg">
        <Row>
          <Col lg="6" className="text-center mx-auto">
            <h3 className="mb-4 tw-text-white">Subscribe to Our Newletter</h3>
            <div className="subscribe">
              <input type="text" placeholder="Email" className="tw-text-gray-800 tw-w-full rounded-lg" />
              <button className="btn pink-bg">Subscribe</button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
