import { ErrorMessage } from "../../components/ErrorMessage";
import React from "react";
import PropTypes from "prop-types";

const variants = {
  OutlineDeeppurple50: "tw-bg-gray_300 tw-border tw-border-deep_purple_50 tw-border-solid",
  UnderLineWhiteA70063: "tw-border-b-[1px] tw-border-white_A700_63",
  OutlineGray6007f: "tw-border-bw12 tw-border-gray_600_7f tw-border-solid",
  OutlineDeeppurple501_2: "tw-bg-deep_purple_101 tw-border tw-border-deep_purple_50 tw-border-solid",
  OutlineGray101: "tw-bg-deep_purple_51 tw-border tw-border-gray_101 tw-border-solid",
  OutlinePurple50: "tw-bg-purple_50 tw-border tw-border-purple_50 tw-border-solid",
  OutlinePurple501_2: "tw-bg-gray_300 tw-border tw-border-purple_50 tw-border-solid",
};
const shapes = {
  RoundedBorder16: "tw-rounded-radius16",
  RoundedBorder2: "tw-rounded-radius24",
};
const sizes = {
  sm: "lg:tw-p-[24px] xl:tw-p-[31px] tw-p-[35px] 3xl:tw-p-[42px]",
  md: "lg:tw-pb-[15px] xl:tw-pb-[19px] tw-pb-[22px] 3xl:tw-pb-[26px]",
};

const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name,
      placeholder,
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      shape,
      variant,
      size,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} tw-bg-transparent tw-border-0`}
            type={type}
            name={name}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder16", "RoundedBorder2"]),
  variant: PropTypes.oneOf([
    "OutlineDeeppurple50",
    "UnderLineWhiteA70063",
    "OutlineGray6007f",
    "OutlineDeeppurple501_2",
    "OutlineGray101",
    "OutlinePurple50",
    "OutlinePurple501_2",
  ]),
  size: PropTypes.oneOf(["sm", "md"]),
};
Input.defaultProps = {
  wrapClassName: "",
  className: "",
  name: "",
  placeholder: "",
  type: "text",
  shape: "",
  variant: "OutlineDeeppurple501_2",
  size: "sm",
};

export { Input };
