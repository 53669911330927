import React from "react";
import { Container, Col } from "reactstrap";
import "./hero-section.css";
import Text from "../Hero-Section/Text";
import Banner from "../Hero-Section/Banner";
import { MColumn } from "components/Column/MColumn";

const HeroSection = () => {
  return (
    <section>
      <Container>
        <MColumn className="tw-flex-col-reverse prose lg:prose-xl">
          <Col>
            <div>
              <Text />
            </div>
          </Col>

          <Col>
            <div>
              <Banner />
            </div>
          </Col>
        </MColumn>
      </Container>
    </section>
  );
};

export default HeroSection;
