import React from "react";
import "./about-us.css";
import { Container, Row, Col } from "reactstrap";
import "./about-us.css";

import Text from "../About-Us/Text";
import Banner from "../About-Us/Banner";

const AboutUs = () => {
  return (
    <section>
      <Container className="about">
        <Row>
          <Col lg="6" md="6">
              <Banner />
          </Col>

          <Col lg="6" md="6">
              <Text />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
