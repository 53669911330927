import React from "react";
import { Link } from "react-router-dom";

const Text = () => {
  return (
    <div className="hero_content">
      <h2 className="mb-4 hero__title">
        Manage your school with 100% efficiency
      </h2>
      <p className="mb-4">
        Brainpiece e-Learning Solutions streamline and simplify school
        traditional mode of operations. It integrates all school administrative
        tasks into one user-friendly database designed to meet the modern needs
        of every school. It eliminates the need for time consuming and
        error-prone duplicate data entry, managing multiple system for the same
        set of students and data loss due to complicated backup processes.
      </p>
      <div className="d-flex gap-2">
        {/* <button className="btn purple-bg">Request a Demo</button> */}
        <Link to="/support" className="btn tw-no-underline tw-bg-deep_purple_A200 hover:tw-bg-deep_purple_A200 text-white">Make an Inquiry</Link>
      </div>
    </div>
  );
};

export default Text;
