import React from "react";
import HeroSection from "../components/Hero-Section/HeroSection";
// import CompanySection from '../components/Company-Section/Company';
import AboutUs from "../components/About-Us/AboutUs";
// import School from '../components/School/School';
import ChooseUs from "../components/Choose-Us/ChooseUs";
import Features from "../components/Feature-Section/Features";
// import FreeCourse from '../components/Free-Course-Section/FreeCourse';
import Benefits from "../components/Benefits/Benefits";
import Newsletter from "../components/Newsletter/Newsletter";
import { Layout } from "components/Layout";

const Home = () => {
  return (
    <Layout>
      <span className="tw-prose lg:tw-prose-xl">
        <HeroSection />

        {/* <CompanySection /> */}

        <AboutUs />

        {/* <School /> */}

        <ChooseUs />
        <Features />

        {/* <FreeCourse /> */}

        <Benefits />
        <Newsletter />
      </span>
    </Layout>
  );
};

export default Home;
