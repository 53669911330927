import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./choose-us.css";
import Text from "../Choose-Us/Text";
import Banner from "../Choose-Us/Banner";

const ChooseUs = () => {
  return (
    <section>
      <Container className="choose-us">
        <Row>
          <Col lg="6">
            <div className="d-none d-md-block">
              <Text />
            </div>
            <div className="d-lg-none">
              <Banner />
            </div>
          </Col>
          <Col lg="6">
            <div className="d-lg-none">
              <Text />
            </div>
            <div className="d-none d-lg-block">
              <Banner />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
