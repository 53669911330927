import React from "react";
// import { motion, AnimatePresence } from "framer-motion";
import { Row, Img } from "components";

const FeatureCard = (props) => {
  // const [isOpen, setIsOpen] = useState(false);
  const { title, icon, bg, color, border } = props.feature;
  console.log(icon)
  return (
    <div className="">
      {/* <div className="max-w-[389.71px] h-[114.84px]"> */}
      <Row
        className={`tw-py-[36px] tw-px-[33px] tw-w-[100%] lg:tw-max-w-[390px] tw-mx-auto ${bg} ${color} ${border} tw-rounded-xl tw-shadow lg:tw-shadow-lg tw-space-x-4`}
      >
        <div>
          {/* {icon} */}
          <Img
            src={icon}
            className="tw-ml-[0] lg:tw-w-[18px] lg:tw-h-[19px] lg:tw-mr-[16px] xl:tw-w-[23px] xl:tw-h-[24px] xl:tw-mr-[20px] 2xl:tw-w-[26px] 2xl:tw-h-[27px] 2xl:tw-mr-[23px] 3xl:tw-w-[31px] 3xl:tw-h-[32px] 3xl:tw-mr-[27px] tw-my-[auto]"
            alt={`${title}`}
          />
        </div>
        <div>{title}</div>
      </Row>

      {/* <motion.div
        transition={{ layout: { duration: 1, type: "spring" } }}
        layout
        onMouseOver={() => setIsOpen(!isOpen)}
        className="card pink-bg"
        style={{
          borderRadius: "1rem",
        }}
      >
        <motion.h6 layout="position">{title}</motion.h6>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              exit={{ opacity: 0 }}
              className="expand"
            >
              <p>{desc}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div> */}
    </div>
  );
};

export default FeatureCard;
