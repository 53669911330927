import React from "react";

const Text = () => {
  return (
    <div className="choose__content">
      <h2 className="d-none d-lg-block">Why Choose Brainpiece</h2>
      <p>
        We understand some challenges facing schools, where the school heads are
        often times expected to carry out some innovative reforms, as well as
        creating a standard structure for the success of the students. The
        e-learning software was developed by a team of expert programmers to
        tackle these various concerns and it is being upgraded from time to time
        base on the curricula demand, consecutive and innovative trends emerged
        in educational sector.
      </p>
    </div>
  );
};

export default Text;
