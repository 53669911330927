import megaphone from "../assets/images/img_megaphone.svg";
import desktop from "../assets/images/Desktop.svg";
import bulb from "../assets/images/Bulb.svg";
import internet from "../assets/images/Internet.svg";
import folder from "../assets/images/Folder.svg";
import folders from "../assets/images/folders.svg";
import timer from "../assets/images/Timer.svg";
import Paycard from "../assets/images/Paycard.svg";
import Download from "../assets/images/Download.svg";
import Upload from "../assets/images/Upload.svg";
import Group_plus from "../assets/images/Group.svg";
import Email from "../assets/images/Email.svg";
import Setting from "../assets/images/Setting.svg";
import Home from "../assets/images/Home.svg";

export const FeatureData = [
  {
    title: "Assignment/Digital Lesson",
    desc: "Assignments can be created with the help of a rich-text editor tool or an assignment file can be uploaded having the assignment. Students can view the list of available assignments for them & can view or download the assignments",
    icon: megaphone,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Computer Based Test (CBT)",
    desc: "Computer Based Test (CBT)",
    icon: desktop,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Student Performance Analysis",
    desc: "Student Performance Analysis",
    icon: bulb,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Online Enrollment",
    desc: "Online Enrollment",
    icon: internet,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "BIO Data and Admission",
    desc: "BIO Data and Admission",
    icon: folder,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Time Table Management",
    desc: "Time tables can be added for classes & teachers.",
    icon: folders,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Student Attendant Management",
    desc: "Students can view their attendance; parents can also watch their children’s attendance from home. Class-teachers / data-entry operators can add-students (class-wise) attendance. While taking the attendance the students leave application is visible if any leave is filed online by the student.",
    icon: timer,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Electronic School fees Payment System",
    desc: "School fees details will be available in this section. Fees can be easily paid online using credit/debit cards or e-checks through a secured & encrypted payment gateway. (online payment facility available in pro version)",
    icon: Paycard,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Student Performance View",
    desc: "Student Performance View",
    icon: bulb,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "PDF Compatible Report Card",
    desc: "This section shows all the results/marks/performance of students. Students can view their marks/report-card in this section. Admin/Parents can watch students/Childs performance.",
    icon: Download,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "E-library",
    desc: "Brainpiece E-Library System streamlines and simplifies E-Library management. It integrates all Library administration tasks into one user-friendly database designed to meet all of your Institution’s needs \n It eliminates the need for book keeping pitfalls and data loss due to overly complicated backup procedures.",
    icon: Upload,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Integrated Bulk SMS",
    desc: "Integrated bulk SMS and e-mail facility.",
    icon: Group_plus,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Integrated Email Platform",
    desc: "Integrated Email Platform",
    icon: Email,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Remote Access",
    desc: "Remote Access",
    icon: Setting,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
  {
    title: "Free Website",
    desc: "Free Website",
    icon: Home,
    color: "tw-text-secondary-900",
    bg: "",
    border: "tw-border"
  },
];
