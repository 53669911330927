import React, { useState } from "react";
import chooseImg from "../../assets/images/why-choose-us.jpg";

import ReactPlayer from "react-player";

const Banner = () => {

    const [showVideo, setShowVideo] = useState(false);

    return <div className="choose__img">
      <h1 className="d-lg-none">Why Choose Brainpiece</h1>
    {showVideo ? (
      <ReactPlayer
        url="https://www.youtube.com/watch?v=qFp27TR4Yew"
        controls
        width="100%"
        height="30px"
      />
    ) : (
      <img src={chooseImg} alt="" className="w-100" />
    )}

    {!showVideo && (
      <span className="play__icon">
        <i
          className="ri-play-circle-line"
          onClick={() => setShowVideo(!showVideo)}
        ></i>
      </span>
    )}
  </div>
}

export default Banner;