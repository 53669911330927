import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Quick Learning",
    desc: "The eLibrary section of our portal system provides an in-house managed electronic library, where all digital content added to the library can be distributed among staff and student simultaneously for online free access",
    icon: "ri-draft-line",
  },
  {
    title: "All time support",
    desc: "We assign a well-trained content manager who will actively update the portal with all of the daily activities that take place within your school. Our support team has a quick turnaround time.",
    icon: "ri-discuss-line",
  },
  {
    title: "Social Events",
    desc: "Brainpiece harnesses the use of embedded social media technology for students engaged learning and teaching interactions on school related activities carried out on a daily basis. One of the major components of the solutions is the social occasion/party planning module; everyone likes to plan for party. It wishes any class member a complimentary greeting on their birthday, birthday party, holiday event, etc.",
    icon: "ri-contacts-book-line",
  },
];

const Features = () => {
  return (
    <section>
      <Container className="feature">
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" key={index}>
              <div className="single__feature text-left d-md-text-center px-4">
                <h2 className="mb-3">
                  <i className={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
