import React from "react";
import "./benefits.css";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";

import img from "../../assets/images/cbt-lab.jpg";

const Benefits = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };
  return (
    <section>
      <Container className="cont">
        <Row>
          <Col lg="10" md="12" className="m-auto">
            <h2 className="mb-0 d-lg-none">Benefits of Brainpiece</h2>
            <div className="testimonial__wrapper d-lg-flex justify-content-between align-items-center ">
              <div className="testimonial__img px-3">
                <img src={img} alt="" className="w-100" />
              </div>

              <div className="testimonial__content w-50">
                <h2 className="mb-4 d-none d-lg-block">Benefits of Brainpiece</h2>

                <Slider {...settings}>
                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">App Responsiveness</h6>
                      <p>
                        It is designed to work on any internet enabled Smart
                        phones; Computer/Laptop, Tablet-Pc, iPads etc., and it
                        can be globally accessible anytime, anywhere
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">Online Assessments</h6>
                      <p>
                        Teachers can set online test, assignments, and upload
                        their lesson notes.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                        Computer Based Test (CBT)
                      </h6>
                      <p>
                        Brainpiece supports a computer-based test (CBT) with a
                        full functional computer laboratory; students can
                        conveniently take their tests online.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                        Great User Experience & Interface
                      </h6>
                      <p>
                        It has a friendly user interface and it is easy to use
                        by the teachers and students no matter their level of
                        computer literacy.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">Dynamic Timetable </h6>
                      <p>It has a dynamic Timetable management feature.</p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">Termly Reports</h6>
                      <p>
                        The platform has a flexible term and semester reports.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">Bulk SMS & Email</h6>
                      <p>
                        The solutions come with a built-in and integrated bulk
                        SMS and e-mail facility.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                        Secure Storage & Data protection
                      </h6>
                      <p>
                        The school records are permanently stored in the
                        database for easy reference and future use.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">Preloaded Past Questions</h6>
                      <p>
                        Preloaded past questions (compendium) and answers of
                        WAEC and NECO examinations
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">Automatic Report Card</h6>
                      <p>
                        Students’ report cards can be easily generated without
                        any rigorous process.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Benefits;
