import React from "react";
import Support from "pages/Support";
import Features from "pages/Features";
import Supportenquiry from "pages/Supportenquiry";
import Supportenquirysent from "pages/Supportenquirysent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";

const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/dhiwise-dashboard" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/supportenquirysent" element={<Supportenquirysent />} />
        <Route path="/supportenquiry" element={<Supportenquiry />} />
        <Route path="/features" element={<Features />} />
        <Route path="/support" element={<Support />} />
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
